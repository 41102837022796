<template>
    <div class="sub_container">
      <div class="sub_wrapper">
        <h2 class="sub-title1">{{ $t('menu2-list') }}</h2>
        <div class="mh-card-table">
          <Stable :columnes="columnesData"
            :tableData="tableData"
            :total_data = "totalCount"
            :loading="loading"
            @handleCurrentChange="handleCurrentChange"></Stable>
        </div>
      </div>
    </div>
</template>
<script>

import Stable from '@/components/Stable.vue';

export default {
  name: 'Blocks',
  components: {
    Stable,
  },
  data() {
    return {
      loading: true,
      columnesData: [
        {
          label: 'columnHeight', prop: 'block_nm', width: '20%', class: '', router: 'blocksDetail', key: 'block_nm',
        },
        {
          label: 'columnValidatorId', prop: 'moniker', width: '20%', class: '', router: 'validatorDetail', key: 'operator_addr',
        },
        {
          label: 'columnTxs', prop: 'num_txs', width: '20%', class: '', router: '', key: '',
        },
        {
          label: 'columnTime', prop: 'time_ago', width: '20%', class: '', router: '', key: '',
        }],
      tableData: [],
      totalCount: 0,
      nIntervId: null,
    };
  },
  methods: {
    detailClick(row) {
      this.$router.push({
        name: 'blocksDetail', params: { me: row.block_nm },
      });
    },
    handleCurrentChange(val) {
      this.loading = true;
      this.getData(val);
    },
    getData(page) {
      this.loading = true;
      this.$axios.get(`/blocks?page_no=${page}&list_size=10`)
        .then((res) => {
          if (res.data.ret_code === 200) {
            this.totalCount = res.data.data.block_tot;
            this.tableData = res.data.data.blocks;
          }
        })
        .catch((error) => { this.$message.error(error); })
        .finally(() => {
          this.loading = false;
        });

      if (page === 1 && this.nIntervId === null) {
        this.nIntervId = setInterval(this.getData, 3000, 1);
      } else if (page > 1 && this.nIntervId != null) {
        clearInterval(this.nIntervId);
        this.nIntervId = null;
      }
    },

  },
  created() {
    this.getData(1);
  },
  beforeDestroy() {
    clearInterval(this.nIntervId);
  },
};
</script>
